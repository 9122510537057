import ApplicationController from '../../support/application_controller'
import I18n                  from '../../support/i18n'
import $fq                   from '../../support/fake_query'

export default class extends ApplicationController {

  static targets = [
    'contactDetails',
    'contactNumber',
    'copyPhysicalAddress',
    'consentGiven',
    'dateOfBirth',
    'firstName',
    'gender',
    'idDocument',
    'idDocumentWrapper',
    'idNumber',
    'idNumberWrapper',
    'idNumberLabel',
    'lastName',
    'medicalAidData',
    'medicalAidDependantNumber',
    'medicalAidName',
    'medicalAidNumber',
    'medicalAidOptionId',
    'medicalAidRequired',
    'medicalAidSearch',
    'newPatientForm',
    'patientType',
    'phAdd1',
    'phCity',
    'phCode',
    'phCountry',
    'phProvince',
    'phSub',
    'poAdd1',
    'poCity',
    'poCode',
    'poCountry',
    'poProvince',
    'poSub',
    'stubHelper',
    'title'
  ]

  static outlets = [
    'search'
  ]

  connect() {
    if (this.hasIdDocumentWrapperTarget) {
      this.toggleIDDetails()
    }
  }

  handleIdNumber() {
    this.guessDateOfBirth()
    this.guessGender()
  }

  selectConsent(event) {
    const element = event.currentTarget

    if (element) {
      const selected = document.querySelector(`[data-consent-document-id="${element.dataset.value}"]`)
      if (element.checked) {
        selected.value = true
      } else {
        selected.value = false
      }
    }
  }

  selectIdDocument() {
    if (this.idDocument === 'za_id') {
      this.idNumberLabelTarget.querySelector('.form-control__label').textContent = I18n.t('dictionary.id_number')
      this.idNumberTarget.setAttribute('data-controller', 'cleave')
    } else if (this.idDocument === 'passport') {
      this.idNumberLabelTarget.querySelector('.form-control__label').textContent = I18n.t('dictionary.passport_number')
      this.idNumberTarget.removeAttribute('data-controller')
    }
  }

  selectTitle() {
    this.guessGenderFromTitle()
  }

  selectMedicalAid(event) {
    const element = event.currentTarget
    if (element) {
      this.medicalAidDataTarget.classList.remove('u-hide')
      this.medicalAidOptionIdTarget.value = element.getAttribute('data-id')
      this.medicalAidNameTarget.textContent = element.getAttribute('data-name')
      this.medicalAidSearchTarget.classList.add('u-hide')
      this.searchOutlet.clearSearch()
    }
  }

  editMedicalAid() {
    this.medicalAidDataTarget.classList.add('u-hide')
    this.medicalAidOptionIdTarget.value = ''
    this.medicalAidNameTarget.textContent = ''
    this.medicalAidSearchTarget.classList.remove('u-hide')
    this.medicalAidNumberTarget.value = ''
    this.medicalAidDependantNumberTarget.value = ''
  }

  submitForm(event) {
    const element = event.currentTarget
    element.innerHTML = element.getAttribute('data-disable-with')
    element.setAttribute('disabled', 'disabled')
    this.newPatientFormTarget.submit()
  }

  toggleMedicalAid(event) {
    if (event.target.value === 'true') {
      this.medicalAidSearchTarget.classList.remove('u-hide')
      this.medicalAidNameTarget.textContent = ''
      this.medicalAidRequiredTarget.value = 'true'
    } else {
      this.medicalAidSearchTarget.classList.add('u-hide')
      this.medicalAidDataTarget.classList.add('u-hide')
      this.medicalAidOptionIdTarget.value = ''
      this.medicalAidNumberTarget.value = ''
      this.medicalAidDependantNumberTarget.value = ''
      this.medicalAidRequiredTarget.value = 'false'
    }
  }

  toggleIDDetails() {
    const date = this.dateOfBirth

    if (date instanceof Date && !Number.isNaN(date.valueOf())) {
      const month       = 1000 * 60 * 60 * 24 * 30
      const today       = new Date().getTime()
      const dob         = date.getTime()
      const diff        = Math.abs(today - dob)
      const ageInMonths = Math.floor(diff / month)

      if (ageInMonths >= 7) {
        $fq(this.idDocumentWrapperTarget).show()
        $fq(this.idNumberWrapperTarget).show()
      } else {
        this.resetIDDetails()
      }
    } else {
      this.resetIDDetails()
    }
  }

  // ==== Helpers

  copyPhysicalAddress() {
    if (this.copyPhysicalAddressTarget.checked === true) {
      this.poAdd1Target.value = this.phAdd1Target.value
      this.poSubTarget.value = this.phSubTarget.value
      this.poCityTarget.value = this.phCityTarget.value
      this.poCodeTarget.value = this.phCodeTarget.value
      this.poCountryTarget.value = this.phCountryTarget.value
      this.poProvinceTarget.value = this.phProvinceTarget.value
    } else {
      this.poAdd1Target.value = ''
      this.poSubTarget.value = ''
      this.poCityTarget.value = ''
      this.poCodeTarget.value = ''
      this.poCountryTarget.value = ''
      this.poProvinceTarget.value = ''
    }
  }

  guessDateOfBirth() {
    const noDateOfBirthProvided = !this.dateOfBirthTarget.value

    if (noDateOfBirthProvided && this.idDocument === 'za_id' && this.idNumber.length >= 6) {
      const year     = this.idNumber.substring(0, 2)
      const month    = this.idNumber.substring(2, 4)
      const day      = this.idNumber.substring(4, 6)
      const yearDiff = (new Date()).getFullYear() - 2000
      const dob      = `${(year > yearDiff ? '19' : '20') + year}/${month}/${day}`

      this.dateOfBirth = dob
    }
  }

  guessGender() {
    const noGenderSelected = !this.genderTarget.value

    if (noGenderSelected && this.idDocument === 'za_id' && this.idNumber.length >= 10) {
      const genderCode = this.idNumber.substring(6, 10)
      const gender     = parseInt(genderCode, 10) < 5000 ? 'female' : 'male'

      this.gender = gender
    }
  }

  guessGenderFromTitle() {
    if (this.title === 'mr') {
      this.gender = 'male'
    } else if (this.title === 'ms') {
      this.gender = 'female'
    } else {
      this.gender = ''
    }
  }

  stubPatient() {
    let data = this.stubHelperTarget.value

    if (data) {
      /* eslint-disable no-multi-spaces */
      data               = data.split('|')
      this.firstName     = data[0]
      this.lastName      = data[1]
      this.gender        = data[2]
      this.dateOfBirth   = data[3]
      this.contactNumber = data[4]
      /* eslint-enable no-multi-spaces */

      this.handleIdNumber()
    }
  }

  manualSave() {}

  // ==== Getters

  get dateOfBirth() {
    const date = this.dateOfBirthTarget.value

    if (date.length === 10) {
      return new Date(date)
    }

    return date
  }

  get idDocument() {
    return this.idDocumentTarget.value
  }

  get idNumber() {
    return this.idNumberTarget.value
  }

  get title() {
    return this.titleTarget.value
  }

  // ==== Setters

  set contactNumber(value) {
    this.contactNumberTarget.querySelector('input').value = value
  }

  set dateOfBirth(value) {
    this.dateOfBirthTarget.value = value
  }

  set firstName(value) {
    this.firstNameTarget.value = value
  }

  set gender(value) {
    this.genderTarget.value = value
  }

  set lastName(value) {
    this.lastNameTarget.value = value
  }

  set title(value) {
    this.titleTarget.value = value
  }

  // ==== Private

  resetIDDetails() {
    $fq(this.idDocumentWrapperTarget).hide()
    $fq(this.idNumberWrapperTarget).hide()

    this.idDocumentTarget.value = ''
    this.idNumberTarget.value = ''
  }

}
